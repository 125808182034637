import { styled, rem } from 'design'
import { default as PLModalCard } from 'design/components/ModalCard/ModalCard'

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(24)} ${rem(24)} 0;
  ${(p) => p.theme.media.tablet} {
    padding: ${rem(48)} ${rem(40)} 0;
  }
`

export const CardWrap = styled.div`
  height: auto;
  overflow-y: hidden;
  padding: 0 ${rem(24)};
  ${(p) => p.theme.media.tablet} {
    height: ${rem(325)};
    overflow-y: visible;
    padding: 0 ${rem(111)} 0 ${rem(40)};
  }
`

export const ModalCard = styled(PLModalCard)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: ${rem(481)};
  min-height: ${rem(200)};
  padding: ${rem(24)};
  margin: 0 0 ${rem(120)};
  ${(p) => p.theme.media.tablet} {
    margin: 0 ${rem(86)} 0 0;
  }
`
