import { Flex } from '@chakra-ui/core'
import { rem } from 'design'
import { SmallButtonPrimary } from 'design/components/Button/Button'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'design/components/Modal/Modal'
import { Body, H4, LinkButton } from 'design/components/Typography/Typography'
import React from 'react'
import PanelLayout from '~/components/CancelFlowModals/PanelCancelLayout'
import {
  ADDRESS_FORM_ID,
  UserShippingAddressForm,
} from '~/components/FormShippingAddress'
import * as Styled from './PanelEditAddress.styles'
import * as TYPES from './PanelEditAddress.types'
const PanelEditAddressUI: React.FC<TYPES.PanelEditAddressUIProps> = ({
  panel,
  user,
  onSaveAction,
}) => {
  const [isFormVisible, setFormVisible] = React.useState(false)
  return (
    <PanelLayout ContentWrapper={null}>
      <ModalContent>
        <Styled.ContentWrap>
          <ModalHeader pb={rem(8)} variant="bare">
            {panel.headline}
          </ModalHeader>
          <Body pb={rem(16)}>{panel.subhead}</Body>
        </Styled.ContentWrap>

        <Styled.CardWrap>
          {!isFormVisible ? (
            <Styled.ModalCard>
              <Flex justifyContent="space-between">
                <H4>Shipping Address</H4>
                <LinkButton onClick={() => setFormVisible(true)}>
                  EDIT
                </LinkButton>
              </Flex>
              <Body>{user.fullName}</Body>
              <Body>
                {user.shippingAddress.addressLineOne}
                {user.shippingAddress.addressLineTwo
                  ? `,${user.shippingAddress.addressLineTwo}`
                  : ''}
              </Body>
              <Body>
                {`${user.shippingAddress.city}, ${user.shippingAddress.state} ${user.shippingAddress.postalCode}`}
              </Body>
            </Styled.ModalCard>
          ) : (
            <>
              <H4 pb={rem(14)}>Shipping Address</H4>
              <UserShippingAddressForm
                modalVariant={true}
                onSave={onSaveAction}
              />
            </>
          )}
        </Styled.CardWrap>
      </ModalContent>
      <ModalFooter>
        {isFormVisible ? (
          <SmallButtonPrimary
            // @ts-ignore
            form={ADDRESS_FORM_ID}
            type="submit"
          >
            Confirm address
          </SmallButtonPrimary>
        ) : (
          <SmallButtonPrimary onClick={() => onSaveAction()}>
            Confirm address
          </SmallButtonPrimary>
        )}
      </ModalFooter>
    </PanelLayout>
  )
}

export default PanelEditAddressUI
