import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import { CANCEL_SAVE_ACTIONS } from '~/analytics/constants'
import { trackCancellationSave } from '~/analytics/events'
import { ReachRouterHistoryContext } from '~/context/reachRouterHistory'
import { ReportAnIssueContext } from '~/context/reportAnIssue'
import { UserContext } from '~/context/user'
import mutateOrderIssue from '~/hooks/mutateOrderIssue'
import mutateSubscriptionIssue from '~/hooks/mutateSubscriptionIssue'
import { captureNetworkError } from '~/analytics/errors'
import PanelEditAddressUI from './PanelEditAddress.ui'
import type { CancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'

const PanelEditAddress: React.FC<CancelPanelProps> = ({
  handleModalClose,
  panel,
}) => {
  const user = React.useContext(UserContext)
  const { orderIssue, affectedOrder } = React.useContext(ReportAnIssueContext)
  const showToast = React.useContext(ToastContext)
  const { getHistoryEntry } = React.useContext(ReachRouterHistoryContext)
  const [mutate] = mutateOrderIssue(affectedOrder.id)
  const [mutateSubIssue] = mutateSubscriptionIssue()

  const sendReplacementBox = React.useCallback(() => {
    return mutate(
      { ...orderIssue, resolution: 'reship' },
      {
        onSuccess: () => {
          showToast('success', {
            children: 'Success! Your replacement box is on the way.',
          })
          trackCancellationSave(
            CANCEL_SAVE_ACTIONS.REPLACEMENT,
            getHistoryEntry(1).pathname,
            getHistoryEntry(2).state.text
          )
          mutateSubIssue({
            issueType: orderIssue.issueType ?? 'other',
            message: orderIssue.message ?? null,
          })
        },
        onError: (e: any) => {
          captureNetworkError(e, {
            pageBeforeReship: getHistoryEntry(-2),
          })
          showToast('error', {
            children:
              'We encountered an issue sending your box, please contact customer support.',
          })
        },
        onSettled: () => {
          handleModalClose()
        },
      }
    )
  }, [
    getHistoryEntry,
    handleModalClose,
    mutate,
    mutateSubIssue,
    orderIssue,
    showToast,
  ])

  return (
    <PanelEditAddressUI
      onSaveAction={sendReplacementBox}
      panel={panel}
      user={user}
    />
  )
}

export default PanelEditAddress
